import http from 'axios';
import { toastr } from 'react-redux-toastr';
import { getCurrentSession } from '../helpers/auth';
import get from 'lodash/get';

const WEB_API_ID = process.env.REACT_APP_WEB_API_ID;
const REGION = process.env.REACT_APP_REGION;

export const errorResponseToastr = (err) => {
  toastr.error('Error', get(err, 'response.data.reason', 'An Error Occured'));
};

export default class WebAPIClient {
  constructor() {
    this.axios = http.create({
      baseURL: `https://${WEB_API_ID}.execute-api.${REGION}.amazonaws.com/v1/web`,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.axios.interceptors.request.use(async function (config) {
      const { accessToken } = await getCurrentSession();

      if (accessToken) {
        config.headers.Authorization = accessToken;
      }
      return config;
    });
  }

  async GET(endpoint = '', params = {}) {
    return this._handleResponse(await this.axios.get(endpoint, { params }));
  }

  async POST(endpoint = '', data = {}, config = {}) {
    return this._handleResponse(await this.axios.post(endpoint, data, config));
  }

  async PUT(endpoint = '', data = {}, config = {}) {
    return this._handleResponse(await this.axios.put(endpoint, data, config));
  }

  async DELETE(endpoint = '', params = {}) {
    return this._handleResponse(await this.axios.delete(endpoint, { params }));
  }

  _handleResponse(response) {
    if (response?.data?.result === 'success') {
      return response?.data?.value;
    }
  }
}
