import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { buildAsyncReducers } from '../thunkTemplate';
import { admin as initialState } from '../initialState';
import WebAPIClient, { errorResponseToastr } from '../../api';

const getSubscribedUsers = createAsyncThunk(
  'admin/getSubscribedUsers',
  async (triggerDevice, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading } = getState().admin;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }
      dispatch(showLoading());

      let users = await new WebAPIClient().GET('/resource/subscribers', {
        org_id: triggerDevice.org_id,
        device_id: triggerDevice.device_id,
        trigger_id: triggerDevice.trigger_id,
      });
      return { subscribedUsers: users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getSubscribedUsers]);
  },
});

// Export the reducer, either as a default or named export
export { getSubscribedUsers };
export default reducer;
