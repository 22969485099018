import { VEREGY_COLORS } from '../../styles/muiTheme';

export const TEMPERATURE = {
  key: 'temperature_2m',
  name: 'Temperature',
  unit: '°F',
  color: VEREGY_COLORS.red,
  tempUnit: true,
};
export const RELATIVE_HUMIDITY = {
  key: 'relative_humidity_2m',
  name: 'Relative Humidity',
  unit: '%',
  color: VEREGY_COLORS.lightblue,
};
export const DEW_POINT = {
  key: 'dew_point_2m',
  name: 'Dew Point',
  unit: '°F',
  color: VEREGY_COLORS.lightblue,
  tempUnit: true,
};
export const APPARENT_TEMPERATURE = {
  key: 'apparent_temperature',
  name: 'Apparent Temperature',
  unit: '°F',
  color: VEREGY_COLORS.orange,
  tempUnit: true,
};
export const PRECIP_PROBABILITY = {
  key: 'precipitation_probability',
  name: 'Precipitation Probability',
  unit: '%',
  color: VEREGY_COLORS.purple,
};
export const PRECIPITATION = {
  key: 'precipitation',
  name: 'Precipitation',
  unit: 'in',
  color: VEREGY_COLORS.purple,
  precipUnit: true,
};
export const RAIN = {
  key: 'rain',
  name: 'Rain',
  unit: 'in',
  color: VEREGY_COLORS.purple,
  precipUnit: true,
};
export const SNOWFALL = {
  key: 'snowfall',
  name: 'Snowfall',
  unit: 'in',
  color: VEREGY_COLORS.blue,
  precipUnit: true,
};
export const WEATHER_CODE = {
  key: 'weather_code',
  name: 'Weather Code',
  unit: '',
  color: VEREGY_COLORS.green,
};
export const SURFACE_PRESSURE = {
  key: 'surface_pressure',
  name: 'Surface Pressure',
  unit: 'hPa',
  color: VEREGY_COLORS.grey,
};
export const CLOUD_COVER = {
  key: 'cloud_cover',
  name: 'Cloud Cover',
  unit: '%',
  color: VEREGY_COLORS.grey,
};
export const WIND_SPEED = {
  key: 'wind_speed_10m',
  name: 'Wind Speed',
  unit: 'mph',
  color: VEREGY_COLORS.yellow,
};
export const WIND_DIRECTION = {
  key: 'wind_direction_10m',
  name: 'Wind Direction',
  unit: '',
  color: VEREGY_COLORS.yellow,
};
export const WIND_GUSTS = {
  key: 'wind_gusts_10m',
  name: 'Wind Gusts',
  unit: 'mph',
  color: VEREGY_COLORS.yellow,
};
export const VISIBILITY = {
  key: 'visibility',
  name: 'Visibility',
  unit: 'm',
  color: VEREGY_COLORS.green,
};
export const UV_INDEX = {
  key: 'uv_index',
  name: 'UV Index',
  unit: '',
  color: VEREGY_COLORS.green,
};
