const details = {
  loading: false,
  currentRequestId: undefined,
  error: null,
};

export const admin = {
  // users: [],
  // niagaras: [],
  // memberships: [],
  subscribedUsers: [],
  ...details,
};

export const alarms = {
  data: [],
  triggers: [],
  ...details,
};

export const appSettings = {
  titleBarStyles: {},
};

export const dialogs = {
  alarm: { id: '', mode: '' },
  subscription: {
    active: false,
    device_id: '',
    name: '',
    subscribed: false,
    trigger_id: '',
    type_: '',
  },
  trigger_device: {
    active: false,
    device_id: '',
    name: '',
    subscribed: false,
    trigger_id: '',
    type: '',
  },
  user: { id: '', mode: '' },
  organization: { id: '', mode: '' },
  membership: { id: '', orgId: '', mode: '' },
  portfolio_membership: { id: '', mode: '' },
  site: { id: '', orgId: '', mode: '' },
  meter: { id: '', mode: '', siteId: '' },
  niagara: { id: '', mode: '' },
  confirm: { title: '', message: '', onConfirm: null },
  trigger: { id: '' },
  info: { title: '', content: '', mode: '' },
  subscribers: { triggerDevice: {} },
  demandSpikeAlarm: {
    allActive: [],
    trigger_device_id: '',
  },
};

export const images = {
  resource_id: '',
  data: [],
  presignedUrls: {},
  ...details,
};

export const kiosk = {
  validLicense: null,
  resource: {},
  sites: [],
  meters: [],
  lastMonth: { rawData: [] },
  images: {
    caseStudy: {},
    data: [],
    selectedIdx: 0,
  },
  settings: {
    selectedEnergy: 0,
    slideHeight: 0,
  },
};

export const licenses = {
  data: [],
  ...details,
};

export const memberships = {
  data: [],
  ...details,
};

export const meters = {
  data: [],
  ...details,
};

export const niagaras = {
  data: [],
  ...details,
};

export const nodes = {
  data: [],
  ...details,
};

export const organizations = {
  data: [],
  userRelations: [],
  portfolioMembers: [],
  licenses: [],
  ...details,
};

export const pages = {
  loaderOpen: false,
  portfolio: { id: '', tab: '', view: '' },
  organization: { id: '', tab: '', view: '' },
  site: { id: '', tab: '', view: '' },
  meter: { id: '', tab: '', view: '' },
  admin: { tab: '', view: '' },
};

export const progress = {
  id: null,
  progress: 0,
  expectedTime: 10000,
  visible: false,
  referredFrom: null,
  ...details,
};

export const rtos = {
  data: [],
  selectedRTO: null,
  instLoadData: [],
  peaks: [],
  ...details,
};

export const sites = {
  data: [],
  weather: {},
  ...details,
};

export const user = {
  item: null,
  memberships: [],
  subscriptions: [],
  ...details,
};
