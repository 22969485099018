import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const FORECAST = 'Forecast';
export const HISTORICAL = 'Historical';
export const TYPES = [FORECAST, HISTORICAL];

function SelectWeatherDataType(props) {
  const { selectedDataType, setSelectedDataType } = props;
  const theme = useTheme();

  const [dataType, setDataType] = useState('');

  useEffect(() => {
    setDataType(selectedDataType);
  }, [selectedDataType]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedDataType(e.target.value);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='weather-data-type-label'
        sx={{ textAlign: 'center', width: '7rem' }}>
        Data Type
      </InputLabel>
      <Select
        disableUnderline
        labelId='weather-data-type-label'
        value={dataType}
        onChange={handleSelect}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={dataType}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
            sx: {
              '& .MuiPaper-root': {
                minWidth: 'unset',
              },
            },
          },
          slotProps: {
            paper: {
              sx: {
                minWidth: 'unset !important',
              },
            },
          },
        }}
        sx={{
          minWidth: '5rem',
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        {TYPES.map((type) => {
          return (
            <MenuItem
              key={type}
              value={type}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {type}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectWeatherDataType.propTypes = {
  selectedDataType: PropTypes.string,
  setSelectedDataType: PropTypes.func,
};

export default SelectWeatherDataType;
