import { toastr } from 'react-redux-toastr';
import {
  CognitoIdentityProviderClient,
  ChangePasswordCommand,
  InitiateAuthCommand,
  ResendConfirmationCodeCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import { APP_ID } from '.';
import { getTokens, removeTokens, setTokens } from './tokens';

const REGION = process.env.REACT_APP_REGION;
const WEBCLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;

class CognitoIdpSdkClient {
  constructor() {
    this.client = new CognitoIdentityProviderClient({ region: REGION });
  }
  async execute(Command, input) {
    return await this.client.send(new Command(input));
  }
}

const changePassword = async (previousPassword, proposedPassword) => {
  const sdkClient = new CognitoIdpSdkClient();
  const { accessToken } = getTokens();
  const response = await sdkClient.execute(ChangePasswordCommand, {
    AccessToken: accessToken,
    PreviousPassword: previousPassword,
    ProposedPassword: proposedPassword,
  });
  return response;
};

const refreshTokens = async (refreshToken) => {
  try {
    const response = await new CognitoIdpSdkClient().execute(
      InitiateAuthCommand,
      {
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: { REFRESH_TOKEN: refreshToken },
        ClientMetadata: { app: APP_ID },
        ClientId: WEBCLIENT_ID,
      }
    );

    const payload = response.AuthenticationResult;
    setTokens(payload);

    return { accessToken: payload.AccessToken, idToken: payload.IdToken };
  } catch (error) {
    console.error(error);
    toastr.error('Error', 'Current session expired');
    removeTokens();
    window.location.href = '/login';
  }
};

const resendConfirmationCode = async (username) => {
  const sdkClient = new CognitoIdpSdkClient();
  const response = await sdkClient.execute(ResendConfirmationCodeCommand, {
    ClientId: WEBCLIENT_ID,
    Username: username,
    ClientMetadata: { app: APP_ID },
  });
  return response;
};

export { changePassword, resendConfirmationCode, refreshTokens };
