import _range from 'lodash/range';

const generateTicks = (start, end) => {
  if (!start || !end) return [];

  if (end.diff(start, 'seconds') < 90000) {
    // return 6am, 12pm and 6 pm
    return [
      start.hour(6).minute(0).unix(),
      start.hour(12).minute(0).unix(),
      start.hour(18).minute(0).unix(),
    ];
  } else {
    // return 12pm for each day in the range
    let ticks = [];
    let currentDate = start.hour(12).minute(0);
    while (currentDate <= end) {
      ticks.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    return ticks.map((tick) => tick.unix());
  }
};

const compileWeatherData = (data, variables) => {
  const hourly = data.hourly();
  let weatherData = _range(
    Number(hourly.time()),
    Number(hourly.timeEnd()),
    hourly.interval()
  ).map((timestamp) => ({ timestamp }));
  for (let i = 0; i < hourly.variablesLength(); i++) {
    const variable = variables[i];
    const varValues = hourly.variables(i).valuesArray();
    for (let j = 0; j < varValues.length; j++) {
      weatherData[j][variable] = varValues[j];
    }
  }
  return weatherData;
};

export { generateTicks, compileWeatherData };
