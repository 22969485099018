import { createAsyncThunk } from '@reduxjs/toolkit';
import isString from 'lodash/isString';
import { push } from 'redux-first-history';
import { setPage } from '.';

const navigate = createAsyncThunk('pages/navigate', async (state, action) => {
  const { dispatch } = action;

  if (state.page === 'org') state.page = 'organization';
  let response = await dispatch(
    setPage({
      page: state.page,
      id: state.id,
      tab: isString(state.tab) ? state.tab : '#dashboard',
      view: isString(state.view) ? state.view : '',
    })
  );

  const pages = response.payload;
  dispatch(push(`/app/${state.page}`));
  return pages;
});

export { navigate };
