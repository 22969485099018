import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';
import {
  TEMPERATURE,
  RELATIVE_HUMIDITY,
  PRECIPITATION,
} from '../../helpers/open-meteo/variables';
import { fetchWeatherData } from '../../helpers/open-meteo/api';
import { updateNodes } from '../nodes';

const getSites = createAsyncThunk(
  'sites/getSites',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().sites;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const sites = await new WebAPIClient().GET('/resource/sites');
    return { data: sites };
  }
);

const putSite = createAsyncThunk(
  'sites/putSite',
  async (site, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allSites } = getState().sites;
    let sites = cloneDeep(allSites);
    try {
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      let updatedSite = await new WebAPIClient().PUT(
        `/resource/sites/${site.site_id}`,
        site
      );
      remove(sites, { site_id: get(updatedSite, 'site_id') });
      sites = concat(sites, updatedSite);
      toastr.success('Site updated');
      dispatch(updateNodes({ sites }));

      return { data: sites };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getWeather = createAsyncThunk(
  'sites/getWeather',
  async (siteId, { dispatch, getState, requestId }) => {
    const { data, weather, currentRequestId, loading } = getState().sites;
    let allWeatherData = cloneDeep(weather);
    try {
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const variables = [
        TEMPERATURE.key,
        RELATIVE_HUMIDITY.key,
        PRECIPITATION.key,
      ];
      const site = data.find((s) => s.site_id === siteId);
      const params = {
        latitude: site.location.lat,
        longitude: site.location.lng,
        hourly: variables,
        temperature_unit: 'fahrenheit',
        timezone: site.timezone.zone,
        past_days: 1,
        forecast_days: 1,
      };

      const weatherData = await fetchWeatherData('Forecast', params);
      const lastUpdate = dayjs();
      const obj = { data: weatherData, lastUpdate };
      allWeatherData[siteId] = obj;
      return { weather: allWeatherData };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getSites, putSite, getWeather };
