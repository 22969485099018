import { createSlice } from '@reduxjs/toolkit';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import { dialogs as initialState } from '../initialState';

const { reducer, actions } = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openInfoDialog: (state, action) => {
      const { title, content } = action.payload;
      state.info.title = title;
      state.info.content = content;
    },
    openSubscribedUsersDialog: (state, action) => {
      state.subscribers.triggerDevice = action.payload;
    },
    openTriggerDeviceDialog: (state, action) => {
      state.trigger_device = action.payload;
    },
    openDemandSpikeAlarmDialog: (state, action) => {
      state.demandSpikeAlarm.allActive = action.payload.allActive;
      state.demandSpikeAlarm.trigger_device_id =
        action.payload.trigger_device_id;
    },
    openConfirmDialog: (state, action) => {
      state.confirm.title = action.payload.title;
      state.confirm.message = action.payload.message;
      state.confirm.onConfirm = action.payload.onConfirm;
    },
    openDialog: (state, action) => {
      const { type_, mode, id, userId, orgId, siteId, meterId, parentIndex } =
        action.payload;

      state[type_].mode = mode;
      state[type_].id = id;
      if (!isEmpty(orgId)) {
        state[type_].orgId = orgId;
      }
      if (!isEmpty(siteId)) {
        state[type_].siteId = siteId;
      }

      if (!isEmpty(meterId)) {
        state[type_].meterId = meterId;
      }
      if (!isEmpty(userId)) {
        state[type_].userId = userId;
      }
      if (parentIndex) {
        state[type_].parentIndex = parentIndex;
      }
    },
    closeDialog: (state) => {
      each(initialState, (val, key) => (state[key] = val));
    },
  },
});

const {
  openDialog,
  openTriggerDeviceDialog,
  openDemandSpikeAlarmDialog,
  openSubscribedUsersDialog,
  openInfoDialog,
  closeDialog,
  openConfirmDialog,
} = actions;
export {
  openDialog,
  openTriggerDeviceDialog,
  openDemandSpikeAlarmDialog,
  openSubscribedUsersDialog,
  openInfoDialog,
  closeDialog,
  openConfirmDialog,
};
export default reducer;
