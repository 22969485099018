import { fetchWeatherApi } from 'openmeteo';
import { FORECAST } from '../../components/selectors/SelectWeatherDataType';
import { compileWeatherData } from './data';

const OPENMETEO_API_ARCHIVE_URL =
  'https://archive-api.open-meteo.com/v1/archive';
const OPENMETEO_API_FORECAST_URL = 'https://api.open-meteo.com/v1/forecast';

const buildParams = (args) => {
  const {
    dataType,
    location,
    variables,
    range,
    pastDays,
    forecastDays,
    timezone,
    temperatureUnit,
    precipitationUnit,
  } = args;
  let params = {
    latitude: location.lat,
    longitude: location.lng,
    hourly: variables,
    timezone: timezone.zone,
    temperature_unit: temperatureUnit.toLowerCase(),
    precipitation_unit: precipitationUnit.toLowerCase(),
    wind_speed_unit: 'mph',
  };

  if (dataType === FORECAST) {
    params.past_days = pastDays;
    params.forecast_days = forecastDays;
  } else {
    params.start_date = range.start.format('YYYY-MM-DD');
    params.end_date = range.end.format('YYYY-MM-DD');
  }

  return params;
};

const fetchWeatherData = async (dataType, params) => {
  let url =
    dataType === FORECAST
      ? OPENMETEO_API_FORECAST_URL
      : OPENMETEO_API_ARCHIVE_URL;
  const responses = await fetchWeatherApi(url, params);
  const _data = compileWeatherData(responses[0], params.hourly);
  return _data;
};

export { buildParams, fetchWeatherData };
